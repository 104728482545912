import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Menu from "../components/common/Menu";

const Library = ({ data }) => {
  const lib = data.sanityPage.libraryTemp;
  return (
    <div
      className="library_main"
      style={{
        backgroundColor: "#fffded",
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            <Link to="/">
              <img
                src={lib.back.asset.url}
                alt={lib.back.asset.altText || "back"}
                className="img-fluid library_tatto1"
              />
            </Link>
            <br />
            <GatsbyImage
              image={lib.banana1.asset.gatsbyImageData}
              alt={lib.banana1.asset.altText || "banana 1"}
              className="img-fluid library_tatto2"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <center>
              <Link to="/">
                <GatsbyImage
                  image={lib.logo.asset.gatsbyImageData}
                  alt={lib.logo.asset.altText || "banana 1"}
                  className="img-fluid library_tatto3"
                  objectFit="contain"
                />
              </Link>
            </center>
            <center>
              <GatsbyImage
                image={lib.lib.asset.gatsbyImageData}
                alt={lib.lib.asset.altText || "Library"}
                className="img-fluid library_tatto4"
                objectFit="contain"
              />
            </center>
            <center>
              <GatsbyImage
                image={lib.rumple.asset.gatsbyImageData}
                alt={lib.rumple.asset.altText || "Rumple"}
                className="img-fluid library_tatto5"
              />
            </center>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
            <GatsbyImage
              image={lib.banana2.asset.gatsbyImageData}
              alt={lib.banana2.asset.altText || "banana 2"}
              className="img-fluid library_tatto6"
              loading="eager"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 element">
            <center>
              <GatsbyImage
                image={lib.butter.asset.gatsbyImageData}
                alt={lib.butter.asset.altText || "Buttercup"}
                className="img-fluid library_tatto7"
                loading="eager"
                objectFit="contain"
              />
            </center>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-7 col-md-7">
            <GatsbyImage
              image={lib.lizard.asset.gatsbyImageData}
              alt={lib.lizard.asset.altText || "Lizard Rumple"}
              className="img-fluid library_tatto10"
              loading="lazy"
              objectFit="contain"
            />
          </div>
          <div className="col-lg-5 col-md-5">
            <GatsbyImage
              image={lib.girl.asset.gatsbyImageData}
              alt={lib.girl.asset.altText || "Girl Above"}
              className="img-fluid library_tatto11"
              loading="lazy"
              objectFit="contain"
            />{" "}
            <br />
            <GatsbyImage
              image={lib.teeth.asset.gatsbyImageData}
              alt={lib.teeth.asset.altText || "Crooked Teeth"}
              className="img-fluid library_tatto12"
              loading="lazy"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <GatsbyImage
              image={lib.wayTo.asset.gatsbyImageData}
              alt={lib.wayTo.asset.altText || "Rumple Features"}
              className="img-fluid library_tatto13"
              loading="lazy"
              objectFit="contain"
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <GatsbyImage
              image={lib.strands.asset.gatsbyImageData}
              alt={lib.strands.asset.altText || "Rumple Features 1"}
              className="img-fluid library_tatto14"
              loading="lazy"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <center>
              <GatsbyImage
                image={lib.frame.asset.gatsbyImageData}
                alt={lib.frame.asset.altText || "Video Frame"}
                className="img-fluid library_tatto15"
                loading="lazy"
                objectFit="contain"
              />
            </center>
            <iframe
              className="purple-iframe"
              src={lib.url}
              frameBorder={0}
              allow="autoplay; fullscreen"
              allowFullScreen=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <GatsbyImage
              image={lib.glass.asset.gatsbyImageData}
              alt={lib.glass.asset.altText || "TrashCan Grab"}
              className="img-fluid library_tatto16"
              loading="lazy"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <a href={lib.bookurl} target="_blank">
              <GatsbyImage
                image={lib.buy.asset.gatsbyImageData}
                alt={lib.buy.asset.altText || "Click here to buy"}
                className="img-fluid library_tatto17"
                loading="lazy"
                objectFit="contain"
              />
            </a>
          </div>
          <div className="col-lg-4 col-md-4">
            <GatsbyImage
              image={lib.man.asset.gatsbyImageData}
              alt={lib.man.asset.altText || "Man"}
              className="img-fluid library_tatto18"
              loading="eager"
              objectFit="contain"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <GatsbyImage
              image={lib.woman.asset.gatsbyImageData}
              alt={lib.woman.asset.altText || "Woman"}
              className="img-fluid library_tatto19"
              loading="eager"
              objectFit="contain"
            />
          </div>
          {/* <div className="col-lg-4 col-md-4">
            <StaticImage
              src="https://www.matthewgraygubler.com/images/library-img/rumplereaching.png"
              className="img-fluid library_tatto20"
              loading="eager"
              objectFit="contain"
            />
          </div> */}
          <div className="col-lg-4 col-md-4">
            <GatsbyImage
              image={lib.banana3.asset.gatsbyImageData}
              alt={lib.banana3.asset.altText || "Banana 3"}
              className="img-fluid library_tatto21"
              loading="eager"
              objectFit="contain"
            />
            <br />
            <GatsbyImage
              image={lib.banana4.asset.gatsbyImageData}
              alt={lib.banana4.asset.altText || "Banana 4"}
              className="img-fluid library_tatto22"
              loading="eager"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "/library" } }) {
      title
      template
      slug {
        current
      }
      libraryTemp {
        back {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            url
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        lib {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        banana1 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        banana2 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        rumple {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        butter {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        lizard {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        girl {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        wayTo {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        teeth {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        strands {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        frame {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        url
        glass {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        buy {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        bookurl
        man {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        woman {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        banana3 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        banana4 {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
      }
    }
  }
`;

export default Library;
